<template>
  <component
    :is="htmlTag"
    class="btn tw-w-auto tw-cursor-pointer tw-rounded tw-border-0 tw-border-solid tw-px-4 tw-py-3 tw-outline-none tw-transition-all tw-duration-150 tw-ease-out"
    tabindex="0"
    :type="!isLink ? 'button' : undefined"
    v-bind="$attrs"
    :data-test-id="dataTestId"
    @keydown.enter="$emit('click')"
    @click="$emit('click', $event)"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: ['click'],
  computed: {
    htmlTag(): 'button' | 'a' {
      return this.isLink ? 'a' : 'button'
    },
  },
})
</script>

<style lang="scss" scoped>
.btn {
  outline: 0px;

  &.disabled,
  &:disabled {
    @apply tw-cursor-not-allowed tw-opacity-50;
  }

  &:not(.cancelBtnStyle) {
    min-height: 42px;

    :slotted(.btnText),
    :slotted(.btnText > *:not(i)) {
      @apply tw-text-center tw-text-sm tw-font-medium;

      line-height: 16px;
    }

    @screen 375 {
      &:not(.small) {
        @apply tw-px-6 tw-py-4;
        min-height: 53px;

        :slotted(.btnText),
        :slotted(.btnText > *) {
          @apply tw-text-base;

          line-height: 19px;
        }
      }
    }
  }
}

.outlined {
  @apply tw-box-border tw-border tw-border-solid tw-border-orange-500 tw-bg-transparent;

  &:hover:not(.selected) {
    @apply tw-bg-orange-500 tw-bg-opacity-5;
    &.custom-hover-bg {
      background-color: var(--hover-bg-color);
    }
  }

  &.selected {
    @apply tw-bg-orange-500 tw-bg-opacity-5 tw-shadow-round;
    border-width: 2px;

    &:slotted(.btnText) {
      @apply tw-font-medium;
    }
  }

  &:deep(.btnText) {
    @apply tw-text-orange-500;
  }
}

.main {
  @apply tw-border tw-border-solid tw-border-orange-500 tw-bg-orange-500 tw-shadow-round;

  :slotted(.btnText) {
    @apply tw-text-white;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    @apply tw-border-orange-600 tw-bg-orange-600;
  }
}

.gray {
  @apply tw-border tw-border-solid tw-border-gray-100 tw-bg-gray-100;

  :slotted(.btnText) {
    @apply tw-text-black;
  }

  &:hover {
    @apply tw-border-gray-200 tw-bg-gray-200 tw-shadow-round;
  }
}

.tertiary {
  &.outlined {
    @apply tw-box-border tw-border tw-border-solid tw-border-green-700 tw-bg-green-200;

    &:hover:not(.selected) {
      @apply tw-bg-green-500 tw-bg-opacity-5;
      &.custom-hover-bg {
        background-color: var(--hover-bg-color);
      }
    }

    &.selected {
      @apply tw-bg-green-500 tw-bg-opacity-5 tw-shadow-round;
      border-width: 2px;

      :slotted(.btnText) {
        @apply tw-font-medium;
      }
    }

    :slotted(.btnText) {
      @apply tw-text-green-700;
    }
  }
}

.brands-spotify {
  @apply tw-border tw-border-solid tw-border-brands-spotify tw-bg-brands-spotify tw-shadow-round;

  :slotted(.btnText) {
    @apply tw-text-white;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    @apply tw-border-green-700 tw-bg-green-700;
  }
}
.brands-soundcloud {
  @apply tw-border tw-border-solid tw-border-brands-soundcloud tw-bg-brands-soundcloud tw-shadow-round;

  :slotted(.btnText) {
    @apply tw-text-white;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    @apply tw-border-orange-600 tw-bg-orange-600;
  }
}
.brands-facebook {
  @apply tw-border tw-border-solid tw-border-brands-facebook tw-bg-brands-facebook tw-shadow-round;

  :slotted(.btnText) {
    @apply tw-text-white;
  }

  &:hover:not(.disabled),
  &:focus:not(.disabled) {
    @apply tw-border-blue-600 tw-bg-blue-600;
  }
}
</style>
